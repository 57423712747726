<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Clienti"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getClienti"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!clienti.length">
          Nessun cliente trovato
        </v-alert>
        <v-list class="mt-2" two-line v-if="clienti.length">
          <template v-for="(cliente, index) in clienti">
            <v-list-tile
              @click="$router.push({ name: 'cliente', params: { id: cliente._id} })"
              avatar
              :key="'tile-'+cliente._id">
              <v-list-tile-avatar>
                <letter-avatar :name="cliente.ragione_sociale" />
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize" v-text="cliente.ragione_sociale"></v-list-tile-title>
                <v-list-tile-sub-title class="text-lowercase" v-text="cliente.mail_ddt"></v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-chip small :color="!cliente.attivo ? 'grey dark-3' : 'success'" text-color="white">
                  <span v-if="cliente.attivo">Attivo</span>
                  <span v-else>Non attivo</span>
                </v-chip>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="cliente._id" v-if="index < clienti.length - 1" inset></v-divider>
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click="$router.push({ name: 'cliente', params: { id: 'add'} })">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo cliente</span>
      </v-tooltip>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import letterAvatar from '@/components/common/LetterAvatar'
import PageTitle from '@/components/common/PageTitle'

export default {
  data () {
    return {
      clienti: [],
      cliente: {},
      search: null,
      pages: 0,
      page: 1,
      paginate: 20
    }
  },
  components: {
    letterAvatar,
    PageTitle
  },
  methods: {
    getClienti () {
      this.$plsqlReadAll('cliente', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.clienti = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore' })
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getClienti()
    }
  },
  mounted () {
    this.getClienti()
  }
}
</script>
