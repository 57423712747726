<script>
/* avatar tondo con due lettere */
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    abbr () {
      return this.name.charAt(0) + this.name.charAt(1)
    }
  }
}
</script>

<template>
  <div class="secondary">
    {{ abbr }}
  </div>
</template>

<style scoped>
  div {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
    border-radius: 50%;
  }
</style>
